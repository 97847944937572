@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Montserrat;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Montserrat-400.woff) format("woff"),
      url(../fonts/Montserrat-400.woff2) format("woff2");
  }
  @font-face {
    font-family: Montserrat;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/Montserrat-500.woff) format("woff"),
      url(../fonts/Montserrat-500.woff2) format("woff2");
  }
  @font-face {
    font-family: Montserrat;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/Montserrat-600.woff) format("woff"),
      url(../fonts/Montserrat-600.woff2) format("woff2");
  }
  @font-face {
    font-family: Montserrat;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/Montserrat-700.woff) format("woff"),
      url(../fonts/Montserrat-700.woff2) format("woff2");
  }

  button {
    font-weight: 500;
  }

  body,
  html {
    scroll-behavior: smooth;
  }
}

@layer components {
  ._container {
    @apply px-4 md:px-6 lg:px-8 mx-auto lg:max-w-[1056px] 2xl:max-w-[1234px];
  }

  .button {
    @apply text-white font-semibold leading-[1.125rem] text-center bg-green-400 rounded-[2.5rem]
    cursor-pointer border border-green-400 transition hover:opacity-70 active:text-green-400 active:bg-white;
  }

  .burger-button {
    @apply self-center order-2 block w-6 h-6 ml-auto bg-transparent bg-center bg-no-repeat bg-cover border-none cursor-pointer xl:hidden;
  }

  ._header.js-header-active {
    @apply fixed top-0 left-0 z-50 w-full h-full bg-white;
  }

  .js-header-active .header__nav {
    @apply flex flex-col order-3 w-full md:items-center md:mt-14 lg:flex-row lg:mt-[40px];
  }

  .js-header-active .header__nav-list {
    @apply block w-auto md:text-base md:text-center md:space-y-4 lg:w-1/2 lg:text-left lg:space-y-4;
  }

  .js-header-active .header__contacts {
    @apply md:flex-col md:items-center md:ml-0 md:text-center md:space-y-6 lg:w-1/2 lg:mb-auto;
  }

  .js-header-active .header__address {
    @apply md:block;
  }

  .js-header-active .header__whatsup {
    @apply md:block;
  }

  .js-header-active .header__telephone {
    @apply md:mx-0;
  }

  .js-header-active .header__callback-button {
    @apply md:block md:mt-[40px] md:mb-[22px] lg:mx-0 lg:mt-0;
  }

  .js-header-active .burger-button.bg-burger {
    @apply hidden;
  }

  .js-header-active .burger-button.bg-cross {
    @apply block;
  }

  .swiper__button {
    @apply block w-11 h-11 bg-white absolute top-[calc(50%-22px)] rounded-[50%] z-[2] cursor-pointer shadow-gray;
  }

  .team-swiper .swiper-pagination-bullet {
    @apply relative flex items-center justify-center w-3 h-3 bg-transparent border opacity-100 border-emerald-400 after:block after:w-1 after:h-1 after:bg-emerald-500 after:rounded-full after:border after:border-emerald-400 after:absolute;
  }

  .team-swiper .swiper-pagination {
    @apply static flex justify-center mt-16 space-x-5;
  }

  .team-swiper .swiper-pagination-bullet-active {
    @apply bg-emerald-500 after:hidden;
  }

  ._yandex-map .ymaps-2-1-79-ground-pane {
    filter: grayscale(100%);
  }

  .js-sections-pagination__bullet.js-sections-pagination__bullet--active {
    @apply bg-emerald-400 border-emerald-400 transition before:content-[none];
  }

  .js-sections-pagination__bullet a {
    @apply absolute w-full h-full;
  }

  .radio-label {
    @apply relative flex text-lg font-medium text-green-50 before:block before:w-6 before:h-6 before:rounded-full before:bg-white before:mr-4;
  }

  .radio-input:checked + .radio-label {
    @apply after:block after:absolute after:w-3 after:h-3 after:bg-blueGray-800 after:rounded-full after:left-[6px] after:top-[6px];
  }
}

@layer utilities {
  .bg-burger {
    background-image: url("../img/burger-button.svg");
  }

  .bg-cross {
    background-image: url("../img/cross.svg");
  }

  .bg-intro--desktop {
    background-image: url("../img/intro/intro-bg--desktop.png");
  }

  @media (max-width: 1279px) {
    .bg-intro--desktop {
      background-size: 445px;
      background-position: right 16px top 40px;
    }
  }

  @media (max-width: 1023px) {
    .bg-intro--desktop {
      background-size: 360px;
      background-position: right 16px top 64px;
    }
  }

  @media (max-width: 767px) {
    .bg-intro--desktop {
      background-size: 325px;
      background-position: top;
    }
  }

  .bg-form-1--tablet {
    background-image: url("../img/form/form-bg1--tablet.png");
  }

  .bg-form-1--desktop {
    background-image: url("../img/form/form-bg1--desktop.png");
  }

  .bg-form-2--mobile {
    background-image: url("../img/form/form-bg2--mobile.png");
  }

  .bg-form-2--tablet {
    background-image: url("../img/form/form-bg2--tablet.png");
  }

  .bg-form-2--desktop {
    background-image: url("../img/form/form-bg2--desktop.png");
  }

  .bg-checkbox {
    background-image: url("../img/check2.svg");
  }

  .bg-team {
    background-image: url("../img/team/team-bg.png");
  }
}

/*Modal styles*/
.modal[aria-hidden="true"] {
  display: none;
}

.modal.is-open {
  display: block;
  z-index: 100;
  position: relative;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.callback-modal__container {
  width: 570px;
  margin: 0 auto;
  padding: 3.5rem 6.25rem;
  background-color: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 0 8px 16px rgba(71, 85, 105, 0.1);
  border-radius: 8px;
  position: fixed;
  top: calc(50% - 253px);
  left: calc(50% - 285px);
  z-index: 10;
}

.callback-modal__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #1e293b;
  margin-bottom: 32px;
}

.callback-modal__close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f5f9;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.callback-modal__close-button svg {
  transform: rotate(45deg);
  fill: #475569;
}

.callback-modal__text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #64748b;
  margin-bottom: 40px;
}

.callback-modal__form {
  display: flex;
  flex-direction: column;
}

.callback-modal__form .callback-modal__name,
.callback-modal__form .callback-modal__tel {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #64748b;
  background-color: transparent;
  border: none;
  padding-bottom: 12px;
  border-bottom: 1px solid #cbd5e1;
  width: 100%;
}

.callback-modal__form .callback-modal__name::placeholder,
.callback-modal__form .callback-modal__tel::placeholder {
  color: #64748b;
}

.callback-modal__form .callback-modal__name:focus-visible,
.callback-modal__form .callback-modal__tel:focus-visible {
  outline: none;
}

.callback-modal__form .callback-modal__name:focus,
.callback-modal__form .callback-modal__tel:focus {
  border-color: #64748b;
}

.callback-modal__privacy-input {
  position: absolute;
  opacity: 0;
}

.callback-modal__privacy-label {
  position: relative;
  cursor: pointer;
}

.callback-modal__privacy-label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  background: transparent;
  border-radius: 4px;
  position: relative;
  border: 1px solid #e2e8f0;
  transition: all 0.5s;
}

.callback-modal__privacy-label:hover::before {
  border: 1px solid #34d399;
}

.callback-modal__privacy-input:checked
  + .callback-modal__privacy-label::before {
  background-image: url("../img/modal-checked__icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #34d399;
  border: 1px solid #34d399;
}

.callback-modal__checkbox-wrap {
  display: flex;
  margin-bottom: 40px;
}

.callback-modal__privacy-text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #94a3b8;
  max-width: 228px;
  margin-left: 12px;
}

.callback-modal__privacy-link {
  text-decoration-line: underline;
  color: #10b981;
}

.callback-modal__button {
  margin-right: auto;
  background-color: #34d399;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: 1px solid #34d399;
  transition: all 0.2s;
  width: 203px;
}

.callback-modal__button:hover {
  opacity: 0.7;
  transition: all 0.2s;
}

.callback-modal__button:active {
  color: #34d399;
  background-color: #ffffff;
  transition: all 0.2s;
}

.form-group--name {
  margin-bottom: 24px;
}

.form-group--tel {
  margin-bottom: 16px;
}

.form-group--modal-name {
  margin-bottom: 32px;
}

.form-group--modal-tel {
  margin-bottom: 24px;
}

.form-group .invalid-feedback {
  position: absolute;
  width: 100%;
  font-size: 10px;
  color: red;
  bottom: -24px;
}

.c-form--callback .form-group .invalid-feedback {
  color: white;
}

.form-group--result {
  display: none;
}

.c-form .required-field {
  display: none;
}

@media (max-width: 1023px) {
  .callback-modal__container {
    width: 535px;
    top: calc(50% - 259px);
    left: calc(50% - 267.5px);
    padding: 3.5rem 4.5rem;
  }

  .callback-modal__title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    margin-bottom: 24px;
  }

  .callback-modal__text {
    text-align: center;
    margin-bottom: 64px;
  }

  .callback-modal__button {
    margin: 0 auto;
  }

  .form-group--tel {
    margin-bottom: 24px;
  }

  .form-group--modal-name {
    margin-bottom: 24px;
  }

  .form-group--modal-tel {
    margin-bottom: 32px;
  }

  .form-group--submit {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .callback-modal__container {
    max-width: 343px;
    left: calc(50% - 171.5px);
    padding: 3.5rem 2rem;
  }

  .callback-modal__text {
    margin-bottom: 40px;
  }
}

/* Модалка успешной отправки формы */

.success-modal[aria-hidden="true"] {
  display: none;
}

.success-modal.is-open {
  display: block;
  z-index: 10;
  position: relative;
}

.success-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-modal__container {
  width: 432px;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  box-shadow: 0px 8px 16px rgba(71, 85, 105, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 56px 85px;
  text-align: center;
  z-index: 20;

  @media (max-width: 767px) {
    width: 343px;
    padding: 56px 32px;
  }
}

.success-modal__close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f5f9;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  & svg {
    transform: rotate(45deg);
    fill: #475569;
  }
}

.success-modal__checked-icon {
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.success-modal__title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #1e293b;
  margin-bottom: 80px;

  @media (max-width: 767px) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 64px;
  }
}

.success-modal__text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #64748b;
}
